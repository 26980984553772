@media screen {
    .card {
        color: var(--text);
        background-color: var(--dark-secondary);
        width: 100%;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border: 1px solid var(--light);
        border-radius: 10px;
    }

    .logo {
        display: inline-block;
    }

    .logo img {
        width: 45px;
        height: 45px;
        border: 3px solid var(--light-secondary);
        border-radius: 50%;
        transition: border 0.5s ease-in-out;
    }

    .logo img:hover {
        border: 3px solid var(--light);
    }

    @media (width >=1200px) {
        .paddingCol img{
            visibility: hidden;
        }
    }

    @media (width < 1200px) {
        .paddingCol {
            display: none;
        }
    }

    .content {
        font-size: 1.25rem;
        max-width: 560px;
        width: 100%;
        vertical-align: top;
        display: inline-block;
    }

    .header {
        margin-bottom: 10px;
    }

    .name {
        font-weight: bold;
        font-size: 1.125rem;
        margin-bottom: 5px;
    }

    .location {
        color: var(--light);
        font-size: 0.95rem;
        font-weight: normal;
    }

    .locationIcon {
        font-size: 0.65rem;
        color: var(--light-secondary);
    }

    .graduated {
        color: var(--light);
        font-size: 0.95rem;
    }

    .calendarIcon {
        font-size: 0.65rem;
        color: var(--light-secondary);
    }

    .degree {
        font-size: 1rem;
        color: var(--text);
        display: inline-block;
        margin-right: 15px;
    }

    .degreeIcon {
        font-size: 1rem;
        color: var(--light-secondary);
    }


    .highlights {
        font-size: 0.75rem;
    }

    .highlights ul {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px;
        padding: 0px;
    }

    .highlights li {
        display: inline-block;
        margin-top: 10px;
        margin-right: 10px;
    }

    .highlights li::before {
        content: "\2605";
        font-size: 0.75rem;
        vertical-align: top;
        color: var(--light);
        margin-right: 3px;
    }

    .arrowContainer {
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }

    .arrowContainer:hover .arrow {
        color: var(--text);
    }

    .arrow {
        font-size: 1.25rem;
        color: var(--light-secondary);
        transition: color 0.5s ease-in-out;
    }

    .detail {
        color: var(--text);
        font-size: 0.8rem;
    }

    .detail ul {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px;
        padding: 0px;
    }

    .detail li:before {
        content: "\2022";
        vertical-align: middle;
        color: var(--light);
        margin-right: 3px;
    }

    .detail li {
        display: block;
    }

    .expandContainer {
        overflow: hidden;
    }

    .expandContent {
        margin-top: -100%;
        font-size: 0rem;
        opacity: 0;
        transition: margin-top 0.5s ease-in-out,
            font-size 0.1s ease-in-out,
            opacity 1s ease-in-out;
    }

    .expandContent.expand {
        margin-top: 0;
        font-size: 0.8rem;
        opacity: 1;
    }

    .hide {
        display: none;
    }

    .show {
        display: block;
    }
}

@media print {
    .card {
        width: 100%;
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
    }

    .logo {
        display: inline-block;
    }

    .logo img {
        width: 45px;
        height: 45px;
        border: 3px solid var(--light-secondary);
        border-radius: 50%;
    }


    .paddingCol {
        display: none;
    }

    .content {
        font-size: 1.25rem;
        max-width: 560px;
        width: 100%;
        vertical-align: top;
        display: inline-block;
    }

    .name {
        font-weight: bold;
        font-size: 1.125rem;
    }

    .location {
        color: var(--light);
        font-size: 0.95rem;
        font-weight: normal;
    }

    .locationIcon {
        font-size: 0.65rem;
        color: var(--light-secondary);
    }

    .graduated {
        color: var(--light);
        font-size: 0.95rem;
    }

    .calendarIcon {
        font-size: 0.65rem;
        color: var(--light-secondary);
    }

    .degree {
        font-size: 1rem;
        display: inline-block;
        margin-right: 15px;
    }

    .degreeIcon {
        font-size: 1rem;
        color: var(--light-secondary);
    }

    .highlights {
        font-size: 0.75rem;
    }

    .highlights ul {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 5px;
        padding: 0px;
    }

    .highlights li {
        display: inline-block;
        margin-right: 10px;
    }

    .highlights li::before {
        content: "\2605";
        font-size: 0.75rem;
        vertical-align: top;
        color: var(--light);
        margin-right: 3px;
    }

    .arrow {
        display: none;
    }

    .detail {
        display: none;
    }
}
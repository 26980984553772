:root {
  --dark: rgb(30, 30, 30);
  --dark-secondary: rgb(45, 51, 61);
  --light: rgb(101, 146, 219);
  --light-secondary: rgb(102, 102, 102);
  --text: rgb(241, 241, 241);
}




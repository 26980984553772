@media screen {
    .header {
        color: var(--text);
        font-size: 1.25rem;
        width: 100%;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        text-shadow: -1px -1px 0 var(--light),
            -1px 1px 0 var(--light),
            1px -1px 0 var(--light),
            1px 1px 0 var(--light);
    }
}

@media print {
    .header {
        font-size: 1.25rem;
        width: 100%;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        border-top: 1px solid black;
    }
}
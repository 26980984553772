@media screen {
    .header{
        z-index: 999;
        background-color: var(--dark);
        position: sticky;
        top: 0px;
    }

    .rowLine {
        border-bottom: 1px solid var(--light-secondary);
        padding-bottom: 0.5rem;
    }

    .headerTitle {
        color: var(--dark);
        font-family: "VT323";
        font-size: 2.5rem;
        text-shadow: -1px -1px 0 var(--light), 
                    -1px  1px 0 var(--light),
                    1px -1px 0 var(--light),
                    1px  1px 0 var(--light);
        transition: color 0.5s ease-in-out;
        
    }

    .headerTitle:hover {
        color: var(--dark-secondary);
    }

    .contentRow {
        padding-top: 0.5rem;
        background-image: linear-gradient(to left, transparent, var(--dark)), url("/img/Voronoi.png");
        background-size: 100%;
    }

    .name {
        color: var(--text);
        font-size: 1.75rem;
        text-shadow: var(--light-secondary) 0 0 20px;
    }

    .titleIcon {
        font-size: 1rem;
        color: var(--light-secondary);
    }

    .title {
        color: var(--light);
        font-size: 1.25rem;
        text-shadow: black 0 0 20px,
        -1px -1px 2px black, 
        -1px  1px 2px black,
        1px -1px 2px black,
        1px  1px 2px black;
    }

    .locationIcon {
        font-size: 1rem;
        color: var(--light-secondary);
    }

    .location {
        color: var(--light);
        font-size: 1.25rem;
        width: max-content;
        text-shadow: black 0 0 20px,
        -1px -1px 2px black, 
        -1px  1px 2px black,
        1px -1px 2px black,
        1px  1px 2px black;
    }

    .emailIcon {
        font-size: 1rem;
        color: var(--light-secondary);
    }

    .email::before {
        content: attr(data-domain) "\0040" attr(data-user);
        unicode-bidi: bidi-override;
        direction: rtl;
        text-shadow: black 0 0 20px,
        -1px -1px 2px black, 
        -1px  1px 2px black,
        1px -1px 2px black,
        1px  1px 2px black;
    }

    .worldMapContainer {
        overflow: hidden;
        display: inline-block;
    }

    @media (pointer:none), (pointer:coarse) {
        .worldMapContainer {
            display: none;
        } 
    }

    .github {
        display: inline-block;
        background-size: 100%;
        width: 30px;
        height: 30px;
        margin: 5px;
        font-size: 0;
        vertical-align: middle;
        transition: background-image 0.5s ease-in-out;
    }

    @media (width >= 992px) {
        .github {
            background-image: url("./img/github-mark/github-mark.png");
        }
    }    

    @media (width < 992px) {
        .github {
            background-image: url("./img/github-mark/github-mark-white.png");
        }
    }    

    .github:hover {
        background-image: url("./img/github-mark/github-mark-white.png");
    }

    .linkedin {
        display: inline-block;
        background-size: 100%;
        margin: 5px;
        width: 30px;
        height: 25px;
        font-size: 0;
        vertical-align: middle;
        transition: background-image 0.5s ease-in-out;
    }

    @media (width >= 992px) {
        .linkedin {
            background-image: url("./img/LinkedIn-Logos/LI-In-Bug-gray.png");
        }
    }    

    @media (width < 992px) {
        .linkedin {
            background-image: url("./img/LinkedIn-Logos/LI-In-Bug.png");
        }
    }

    .linkedin:hover {
        background-image: url("./img/LinkedIn-Logos/LI-In-Bug.png");
    }

    .pdf {
        display: inline-block;
        width: 30px;
        height: 25px;
        cursor: pointer;
    }

    .pdfIcon {
        position: relative;
        top: 5px;
        font-size: 1.7rem;
        transition: color 0.5s ease-in-out;
    }

    @media (width >= 992px) {
        .pdfIcon {
            color: var(--dark-secondary);
        }
    }    

    @media (width < 992px) {
        .pdfIcon {
            color: var(--text);
        }
    }


    .pdfIcon:hover {
        color: var(--text);
    }

    .summary {
        color: var(--text);
        vertical-align: bottom;
    }

    .nav {
        text-align: center;
        color: var(--light-secondary);
    }

    .nav a {
        color: var(--light);
        text-decoration: none;
    }
}

@media print {
    
    .headerTitle {
        display: none;
    }

    .contentRow {
        padding-top: 0.5rem;
        background-image: linear-gradient(to left, transparent, white), url("/img/Voronoi.png");
        background-size: 100%;
    }

    .name {
        font-size: 1.75rem;
    }

    .titleIcon {
        font-size: 1rem;
        color: black;
    }

    .title {
        color: var(--light);
        font-size: 1.25rem;
        text-shadow: white 0 0 20px,
        -1px -1px 1px white, 
        -1px  1px 1px white,
        1px -1px 1px white,
        1px  1px 1px white;
    }

    .locationIcon {
        font-size: 1rem;
        color: black;
    }

    .location {
        color: var(--light);
        font-size: 1.25rem;
        width: max-content;
        text-shadow: white 0 0 20px,
        -1px -1px 1px white, 
        -1px  1px 1px white,
        1px -1px 1px white,
        1px  1px 1px white;
    }

    .emailIcon {
        font-size: 1rem;
        color: var(--light-secondary);
    }

    .email::before {
        content: attr(data-domain) "\0040" attr(data-user);
        unicode-bidi: bidi-override;
        direction: rtl;
        text-shadow: white 0 0 20px,
        -1px -1px 1px white, 
        -1px  1px 1px white,
        1px -1px 1px white,
        1px  1px 1px white;
    }

    .github {
        display: none;
    }

    .linkedin {
        display: none;
    }

    .pdf {
        display: none;
    }

    .summary {
        display: none;
    }

    .nav {
        display: none;
    }
}


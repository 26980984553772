@media screen {
    .card {
        color: var(--text);
        background-color: var(--dark-secondary);
        width: 100%;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border: 1px solid var(--light);
        border-radius: 10px;
    }

    .image img {
        margin-left: auto;
        margin-right: auto;
        display: block;
        border: 2px solid var(--light-secondary)
    }

    @media (width >= 992px) {
        .image img {
            max-height: 180px;
        }
    }    

    @media (width < 992px) {
        .image img {
            max-height: 45px;
        }
    }    

    .title {
        font-weight: bold;
        font-size: 1.125rem;
    }

    @media (width >= 992px) {
        .title {
            text-align: center;
        }
    }    

    @media (width < 992px) {
        .title {
            text-align: left;
        }
    }    

    .title a {
        color: var(--light);
        text-decoration: none;
    }

    .title a:hover {
        text-decoration: underline;
    }

    .linkIcon {
        font-size: .75rem;
        color: var(--light-secondary);
        vertical-align: middle;
        display: inline-block;
    }


    .skills {
        font-size: 0.75rem;
    }

    .skills ul{
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px;
        padding: 0px;
    }

    .skills li{
        display: inline-block;
        margin-top: 10px;
        margin-right: 10px;
    }

    .skills li::before{
        content: "\2605";
        font-family: monospace;
        font-size: 0.75rem;
        vertical-align: top;
        color: var(--light);
        margin-right: 3px;
    }

    .description {
        font-size: 0.9rem;
        margin-bottom: 5px;;
    }

    .hide {
        display: none;
    }

}

@media print {
    .card {
        width: 100%;
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
    
    }

    .image img {
        max-height: 45px;
        display: block;
    }

    .title {
        font-weight: bold;
        font-size: 1rem;
    }

    .title a {
        color: var(--light);
        text-decoration: none;
    }

    .linkIcon {
        font-size: .75rem;
        color: var(--light-secondary);
        vertical-align: middle;
        display: inline-block;
    }


    .skills {
        font-size: 0.75rem;
    }

    .skills ul{
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 5px;
        padding: 0px;
    }

    .skills li{
        display: inline-block;
        margin-top: 5px;
        margin-right: 10px;
    }

    .skills li::before{
        content: "\2605";
        font-family: monospace;
        font-size: 0.75rem;
        vertical-align: top;
        color: var(--light);
        margin-right: 3px;
    }

    .description {
        font-size: 0.9rem;
        margin-bottom: 5px;
    }
}